<template>
  <div class="py-16 pb-0">
    <div class="container mx-auto mb-24">
      <h1 class="mb-16 mb-4 text-center font-merri text-4xl">Our Food</h1>
      <h2
        class="mb-4 mb-8 px-2 text-center text-xl font-semibold sm:px-0 sm:px-8"
      >
        High Meat Content, Grain Free, Premium food.
      </h2>
      <div class="mx-auto mb-12 w-full sm:mb-16 sm:w-2/3">
        <p class="mb-8 text-center">
          We’ve carefully formulated most of our wet and dry food to be
          nutritionally complete, with some Complementary options, using only
          premium grade ingredients. This helps ensure your cat thrives, giving
          you peace of mind their long term health is taken care of.
        </p>
        <div class="relative grid grid-cols-3 grid-rows-3 gap-4">
          <div
            class="col-span-3 rounded-lg border-2 border-teal-400 p-4 sm:col-span-1 sm:row-span-2 sm:row-start-1"
          >
            <strong>High meat content...</strong> Cats are 100% carnivores, it’s
            essential they receive a high meat content to avoid potential health
            issues
          </div>
          <div
            class="col-span-3 rounded-lg border-2 border-teal-300 p-4 sm:col-span-1 sm:row-span-2 sm:row-start-2"
          >
            <strong>Grain Free...</strong> Because cats sensitive little
            stomachs weren’t designed to digest most grains
          </div>
          <div
            class="col-span-3 rounded-lg border-2 border-teal-200 p-4 sm:col-span-1 sm:row-span-2 sm:row-start-1"
          >
            <strong>No Added Sugar...</strong> Because sugar, commonly added to
            wet food, is not ideal for cats teeth or their sensitive stomachs.
          </div>
        </div>

        <feeding-guidelines-link class="pb-4 sm:pb-6" />
      </div>
      <div class="mb-4 text-center text-gray-700">Show me...</div>
      <div class="mx-auto w-full sm:w-1/3">
        <div class="flex justify-center text-gray-700">
          <div class="flex w-full space-x-2 rounded-lg bg-gray-200 p-2">
            <div
              @click="activeCat.isKitten = false"
              class="relative flex w-1/2 cursor-pointer items-center rounded px-4 py-2 text-center hover:bg-gray-300"
              :class="{
                'cursor-default bg-gray-600 text-white hover:bg-gray-600':
                  !activeCat.isKitten
              }"
            >
              <div
                class="flex h-6 w-6 flex-none items-center justify-center rounded-full bg-white"
                v-if="!activeCat.isKitten"
              >
                <font-awesome-icon
                  class="text-gray-500"
                  v-if="!activeCat.isKitten"
                  :icon="['far', 'check']"
                />
              </div>
              <div class="mx-auto">Adult</div>
            </div>
            <div
              @click="activeCat.isKitten = true"
              class="relative flex w-1/2 cursor-pointer items-center rounded px-4 py-2 text-center hover:bg-gray-300"
              :class="{
                'cursor-default bg-gray-600 text-white hover:bg-gray-600':
                  activeCat.isKitten
              }"
            >
              <div
                class="flex h-6 w-6 flex-none items-center justify-center rounded-full bg-white"
                v-if="activeCat.isKitten"
              >
                <font-awesome-icon
                  class="text-gray-500"
                  v-if="activeCat.isKitten"
                  :icon="['far', 'check']"
                />
              </div>
              <div class="mx-auto">Kitten</div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="mb-6 px-4 pt-6 text-center text-lg font-semibold"
        id="food-selection-start"
      >
        Discover our wide variety of
        {{ activeCat.isKitten ? 'Kitten' : 'Adult' }} food to choose in your
        subscription
      </div>
      <div>
        <product-selection v-if="products" :cat="activeCat" :isAdjust="false" />
      </div>
    </div>

    <div class="mb-8 px-4 text-center sm:px-0" v-if="!isLoggedIn">
      Our Taster Box is full of our most popular food so they can enjoy finding
      their favourites...
    </div>
    <div
      v-if="!isLoggedIn"
      class="flex flex-col items-center space-y-6 px-6 py-24 pt-3 sm:space-x-6 sm:px-0"
    >
      <action-ctas class="w-full sm:w-auto" />
    </div>
  </div>
</template>

<script>
import ActionCtas from '@/components/ActionCtas';
import FeedingGuidelinesLink from '@/components/FeedingGuidelinesLink';

import { mapGetters, mapActions } from 'vuex';
import ProductSelection from '@/components/products/ProductSelection.vue';

export default {
  metaInfo: {
    title: 'Grain Free Wet & Dry Cat Food from The Cool Cat Club',
    meta: [
      {
        name: 'description',
        content:
          'Created around the finest ingredients for a Grain Free & Real Meat Wet & Dry Cat Food Service, The Cool Cat Club can help to create a healthy diet for your cat.'
      }
    ]
  },
  components: {
    ActionCtas,
    FeedingGuidelinesLink,

    ProductSelection
  },
  data() {
    return {
      selectedWet: 'chicken',
      selectedDry: 'salmon',
      activeCat: { isKitten: false, products: [] }
    };
  },
  computed: {
    ...mapGetters({
      products: 'getProducts',
      isLoggedIn: 'isLoggedIn'
    })
  },
  methods: {
    ...mapActions({
      sendEvent: 'sendMpEvent'
    }),
    updateRecipe: function (value) {
      if (value.type === 'wet') {
        this.selectedWet = value.recipe;
      } else {
        this.selectedDry = value.recipe;
      }
    }
  },
  mounted() {
    if (this.$route?.params?.id || this.$route?.query?.id) {
      const id = this.$route?.params?.id || this.$route?.query?.id;
      const el = document.getElementById(id);

      if (el) {
        this.$nextTick(() => {
          el.scrollIntoView({ behavior: 'instant' });
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.rotate-12 {
  transform: rotate(-12deg);
}
.rotate-18 {
  transform: rotate(18deg);
}
.pink-fill {
  fill: #f687b3;
}
.green-fill {
  fill: #4fd1c5;
}

.filler {
  @apply fill-current;
}
.scrollactive-item.is-active {
  @apply bg-gray-800 text-white;

  .span {
    @apply text-gray-300;
  }
}
</style>
