var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"py-16 pb-0"},[_c('div',{staticClass:"container mx-auto mb-24"},[_c('h1',{staticClass:"mb-16 mb-4 text-center font-merri text-4xl"},[_vm._v("Our Food")]),_c('h2',{staticClass:"mb-4 mb-8 px-2 text-center text-xl font-semibold sm:px-0 sm:px-8"},[_vm._v(" High Meat Content, Grain Free, Premium food. ")]),_c('div',{staticClass:"mx-auto mb-12 w-full sm:mb-16 sm:w-2/3"},[_c('p',{staticClass:"mb-8 text-center"},[_vm._v(" We’ve carefully formulated most of our wet and dry food to be nutritionally complete, with some Complementary options, using only premium grade ingredients. This helps ensure your cat thrives, giving you peace of mind their long term health is taken care of. ")]),_vm._m(0),_c('feeding-guidelines-link',{staticClass:"pb-4 sm:pb-6"})],1),_c('div',{staticClass:"mb-4 text-center text-gray-700"},[_vm._v("Show me...")]),_c('div',{staticClass:"mx-auto w-full sm:w-1/3"},[_c('div',{staticClass:"flex justify-center text-gray-700"},[_c('div',{staticClass:"flex w-full space-x-2 rounded-lg bg-gray-200 p-2"},[_c('div',{staticClass:"relative flex w-1/2 cursor-pointer items-center rounded px-4 py-2 text-center hover:bg-gray-300",class:{
              'cursor-default bg-gray-600 text-white hover:bg-gray-600':
                !_vm.activeCat.isKitten
            },on:{"click":function($event){_vm.activeCat.isKitten = false}}},[(!_vm.activeCat.isKitten)?_c('div',{staticClass:"flex h-6 w-6 flex-none items-center justify-center rounded-full bg-white"},[(!_vm.activeCat.isKitten)?_c('font-awesome-icon',{staticClass:"text-gray-500",attrs:{"icon":['far', 'check']}}):_vm._e()],1):_vm._e(),_c('div',{staticClass:"mx-auto"},[_vm._v("Adult")])]),_c('div',{staticClass:"relative flex w-1/2 cursor-pointer items-center rounded px-4 py-2 text-center hover:bg-gray-300",class:{
              'cursor-default bg-gray-600 text-white hover:bg-gray-600':
                _vm.activeCat.isKitten
            },on:{"click":function($event){_vm.activeCat.isKitten = true}}},[(_vm.activeCat.isKitten)?_c('div',{staticClass:"flex h-6 w-6 flex-none items-center justify-center rounded-full bg-white"},[(_vm.activeCat.isKitten)?_c('font-awesome-icon',{staticClass:"text-gray-500",attrs:{"icon":['far', 'check']}}):_vm._e()],1):_vm._e(),_c('div',{staticClass:"mx-auto"},[_vm._v("Kitten")])])])])]),_c('div',{staticClass:"mb-6 px-4 pt-6 text-center text-lg font-semibold",attrs:{"id":"food-selection-start"}},[_vm._v(" Discover our wide variety of "+_vm._s(_vm.activeCat.isKitten ? 'Kitten' : 'Adult')+" food to choose in your subscription ")]),_c('div',[(_vm.products)?_c('product-selection',{attrs:{"cat":_vm.activeCat,"isAdjust":false}}):_vm._e()],1)]),(!_vm.isLoggedIn)?_c('div',{staticClass:"mb-8 px-4 text-center sm:px-0"},[_vm._v(" Our Taster Box is full of our most popular food so they can enjoy finding their favourites... ")]):_vm._e(),(!_vm.isLoggedIn)?_c('div',{staticClass:"flex flex-col items-center space-y-6 px-6 py-24 pt-3 sm:space-x-6 sm:px-0"},[_c('action-ctas',{staticClass:"w-full sm:w-auto"})],1):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative grid grid-cols-3 grid-rows-3 gap-4"},[_c('div',{staticClass:"col-span-3 rounded-lg border-2 border-teal-400 p-4 sm:col-span-1 sm:row-span-2 sm:row-start-1"},[_c('strong',[_vm._v("High meat content...")]),_vm._v(" Cats are 100% carnivores, it’s essential they receive a high meat content to avoid potential health issues ")]),_c('div',{staticClass:"col-span-3 rounded-lg border-2 border-teal-300 p-4 sm:col-span-1 sm:row-span-2 sm:row-start-2"},[_c('strong',[_vm._v("Grain Free...")]),_vm._v(" Because cats sensitive little stomachs weren’t designed to digest most grains ")]),_c('div',{staticClass:"col-span-3 rounded-lg border-2 border-teal-200 p-4 sm:col-span-1 sm:row-span-2 sm:row-start-1"},[_c('strong',[_vm._v("No Added Sugar...")]),_vm._v(" Because sugar, commonly added to wet food, is not ideal for cats teeth or their sensitive stomachs. ")])])
}]

export { render, staticRenderFns }