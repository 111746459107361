<template>
  <div
    class="text-center my-8 flex flex-col sm:flex-row sm:justify-center"
    @click="gotTo"
  >
    <div class="sm:mr-2">
      <font-awesome-icon class="mr-2 " :icon="['far', 'book-open']" />
      Unsure how much to feed?
    </div>
    <div class="underline">
      View our feeding guidelines
      <font-awesome-icon :icon="['far', 'arrow-right-long']" />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  props: {
    category: String
  },
  computed: {
    currentRoute() {
      return this.$router.currentRoute.name;
    }
  },
  methods: {
    ...mapActions({
      sendEvent: 'sendMpEvent'
    }),
    gotTo() {
      this.sendEvent({
        event: 'View feeding guidelines',
        properties: {
          location: this.$route.name,
          type: 'EVT'
        }
      });

      this.$router.push({
        name: 'feeding guidelines',
        params: {
          category: this.category
        }
      });
    }
  }
};
</script>

<style lang="css" scoped></style>
